import React, { Component } from "react";
import { connect } from "react-redux";

class Download extends Component {
    render = () => {
        const { subscription_type, subscriptions } = this.props.user

        return (
            <div className="ps-3">
                {
                    subscription_type === "app_access" && subscriptions.indexOf("facebook") !== -1 ?
                        <>
                            <div className="row">
                                <h4 className="text-dark py-3">FB Marketplace</h4>
                            </div>

                            <a href="https://storage.googleapis.com/lazyposter-app/windows/lp-win_latest.zip" download className="btn btn-dark btn-lg me-4">
                                <i className="fab fa-windows"></i> Windows
                            </a>

                            <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-macos_latest.zip" download className="btn btn-dark btn-lg me-4">
                                <i className="fab fa-apple"></i> MacOS
                            </a>

                            {/* <a href="https://storage.googleapis.com/lazyposter-app/linux/lp-linux_latest.zip" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                                <i className="fab fa-ubuntu"></i> Linux
                            </a> */}

                            <a href="https://storage.googleapis.com/lazyposter-app/instructions_fb.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                                <i className="far fa-file-pdf"></i> Guide
                            </a>

                            <hr className="mt-5" />
                        </> : null
                }

                {
                    subscription_type === "app_access" && subscriptions.indexOf("craigslist") !== -1 ?
                        <>
                            <div className="row">
                                <h4 className="text-dark py-3">Craigslist</h4>
                            </div>

                            <a href="https://storage.googleapis.com/lazyposter-app/windows/lp-win-cl_latest.zip" download className="btn btn-dark btn-lg me-4">
                                <i className="fab fa-windows"></i> Windows
                            </a>

                            <a href="https://storage.googleapis.com/lazyposter-app/macos/lp-macos-cl_latest.zip" download className="btn btn-dark btn-lg me-4">
                                <i className="fab fa-apple"></i> MacOS
                            </a>

                            <a href="https://storage.googleapis.com/lazyposter-app/instructions_cl.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                                <i className="far fa-file-pdf"></i> Guide
                            </a>

                            <hr className="mt-5" />
                        </> : null
                }

                {
                    subscription_type === "app_access" && subscriptions.indexOf("offerup") !== -1 ?
                        <>
                            <div className="row">
                                <h4 className="text-dark py-3">Offerup</h4>
                            </div>

                            <a href="https://storage.googleapis.com/lazyposter-app/offerup/offerup.zip" download className="btn btn-dark btn-lg me-4">
                                <i className="fab fa-windows"></i> Windows
                            </a>

                            <a href="https://storage.googleapis.com/lazyposter-app/instructions_offerup.pdf" download target="_blank" rel="noreferrer" className="btn btn-dark btn-lg">
                                <i className="far fa-file-pdf"></i> Guide
                            </a>

                        </> : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});


export default connect(
    mapStateToProps,
    null
)(Download);