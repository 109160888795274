
export const updateUser = (user) => ({
    type: "UPDATE_USER",
    user,
});

export const removeUser = (user) => ({
    type: "REMOVE_USER",
    user,
});
