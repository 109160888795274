import React, { Component } from "react";
import utf8 from 'utf8'
import base64 from "base-64";
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { inflateSync } from 'zlib';

import Login from './containers/Login'
import Dashboard from './containers/Dashboard'
import Tutorials from './containers/Tutorials'
import AddListing from './containers/AddListing'
import ViewListings from './containers/ViewListings'
import Download from './containers/Download'
import { connect } from "react-redux";
import { updateUser } from "./actions";
import ImportExport from "./containers/ImportExport";
import Settings from "./containers/Settings";
import Logo from "./lazysmallpng.PNG";

const decodeUserData = userData => JSON.parse(utf8.decode(base64.decode(userData)))
const decodeListingData = listingData => listingData ? JSON.parse(inflateSync(Buffer.from(listingData, "base64")).toString()) : []

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: true
    }

    const userData = localStorage.getItem("user");
    const listingData = localStorage.getItem("listings");

    if (userData) {
      try {
        const user = decodeUserData(userData)
        const listings = decodeListingData(listingData)
        props.updateUser({ ...user, listings })
      } catch (err) {
        console.error(err)
        localStorage.removeItem("user");
        localStorage.removeItem("listings");
      }

    }

  }
  toggleSidebar = () => {
    const { active } = this.state
    this.setState({ active: !active })
  };


  logout = () => {
    this.props.updateUser({});
    window.location.href = "/";
  };

  render = () => {
    const { active } = this.state
    const { user } = this.props
    return (
      <Router>
        {user.email ?
          <div>
            <div className={`side-navbar d-flex justify-content-between flex-wrap flex-column ${active ? "active-nav" : ""}`}>
              <ul className="nav flex-column text-white w-100">
                <li className="nav-link h3 text-white my-2">
                  <img src={Logo} width="95%" alt="" />
                </li>
                <Link to="/dashboard" className="nav-link text-white">
                  <i className="fas fa-home"></i>
                  <span className="mx-2">Home</span>
                </Link>
                <Link to="/tutorials" className="nav-link text-white">
                  <i className="fab fa-youtube"></i>
                  <span className="mx-2">Tutorials</span>
                </Link>
                <Link to="/add-listing" className="nav-link text-white">
                  <i className="far fa-plus-square"></i>
                  <span className="mx-2">Add Listing</span>
                </Link>
                <Link to="/view-listings" className="nav-link text-white">
                  <i className="fas fa-list-ul"></i>
                  <span className="mx-2">View Listings</span>
                </Link>
                <Link to="/imex" className="nav-link text-white">
                  <i className="fas fa-file-csv"></i>
                  <span className="mx-2">Import/Export</span>
                </Link>
                <Link to="/download" className="nav-link text-white">
                  <i className="fas fa-download"></i>
                  <span className="mx-2">Download Apps</span>
                </Link>
                <Link to="/settings" className="nav-link text-white">
                  <i className="fas fa-cog"></i>
                  <span className="mx-2">Settings</span>
                </Link>

                <Link to="#" className="nav-link text-white" onClick={this.logout}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span className="mx-2">Logout</span>
                </Link>
              </ul>
            </div>
            <div className={`p-1 my-container vh-100 ${active ? "active-cont" : ""}`} >
              <nav className="navbar top-navbar navbar-light bg-light px-5 justify-content-start" onClick={this.toggleSidebar}>
                <Link to="#" className="btn border-0" id="menu-btn"><i className="fas fa-bars"></i></Link>
                {user.subscription_active ? null : <h3 className="text-left text-danger ms-5">Subscription expired! Please renew your subscription.</h3>}
              </nav>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/tutorials" component={Tutorials} />
                <Route exact path="/add-listing" component={AddListing} key="add-listing" />
                <Route exact path="/edit-listing/:id" component={AddListing} key="edit-listing" />
                <Route exact path="/view-listings" component={ViewListings} />
                <Route exact path="/imex" component={ImportExport} />
                <Route exact path="/download" component={Download} />
                <Route exact path="/settings" component={Settings} />
              </Switch>
            </div>
          </div>

          :
          <Switch>
            <Route path="/" component={Login} />
          </Switch>
        }
      </Router>
    );
  }

}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (user) => dispatch(updateUser(user)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
